<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Informasjonskapsler
        </h1>
        <div class="article-body clearfix mb-3">
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'cookies-page',
  extends: Page
};
</script>
